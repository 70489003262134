var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('section',{staticClass:"section-imaginary"},[_c('v-carousel',{staticClass:"section-imaginary__items",attrs:{"hide-delimiters":"","height":"100%","interval":2500,"cycle":""}},[_c('v-carousel-item',[_c('v-img',{attrs:{"src":(_vm.$assetsEndpoint + "/banner01.png"),"height":"100%","min-height":"125px","max-height":"400px"}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":(_vm.$assetsEndpoint + "/banner02.png"),"min-height":"125px","max-height":"400px","contain":""}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":(_vm.$assetsEndpoint + "/banner03.png"),"min-height":"125px","max-height":"400px","contain":""}})],1)],1)],1),_c('section',{staticClass:"section-trustfactor"},[_c('v-container',[_c('v-row',_vm._l((_vm.trustFactors),function(trustFactor,index){return _c('v-col',{key:("trust-factors-" + index),attrs:{"cols":"12","sm":"4"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('trustfactor-item',{attrs:{"imagePath":(_vm.$assetsEndpoint + "/" + (trustFactor.imagePath)),"title":trustFactor.title,"subtitle":trustFactor.subtitle,"to":{ name: trustFactor.to }}})]},proxy:true},{key:"loggedOut",fn:function(){return [_c('trustfactor-item',{attrs:{"imagePath":(_vm.$assetsEndpoint + "/" + (trustFactor.imagePath)),"title":trustFactor.title,"subtitle":trustFactor.subtitle,"to":{ name: 'Login' }}})]},proxy:true}],null,true)})],1)}),1)],1)],1),_c('section',{staticClass:"section-game-category"},[_c('v-container',[_c('v-row',_vm._l((_vm.categories),function(category,index){return _c('v-col',{key:("category-" + index),attrs:{"cols":"6"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('center',[_c('a',{attrs:{"href":_vm.generateGameLobbyUrl(_vm.getIdByGame(category.game), category.table_type),"target":"_blank"},on:{"click":function($event){_vm.updateRecentGame({
                    type: category.table_type,
                    gameId: _vm.getIdByGame(category.game),
                    imageUrl: (_vm.$assetsEndpoint + "/" + (category.name) + ".png")
                  })}}},[_c('img',{attrs:{"width":"70%","src":(_vm.$assetsEndpoint + "/" + (category.name) + ".png"),"alt":"category"}})])])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('center',[_c('img',{attrs:{"width":"70%","src":(_vm.$assetsEndpoint + "/" + (category.name) + ".png"),"alt":"category"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby(category.game, category.table_type)}}})])]},proxy:true}],null,true)})],1)}),1)],1)],1),_c('section',{staticClass:"section-bikini"},[_c('v-container',{staticClass:"pb-0"},[_c('v-row',{staticClass:"my-5",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title d-inline-block"},[_vm._v(" PGSLOT ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('v-btn',{staticClass:"float-right",attrs:{"text":"","rounded":"","color":"pink","href":_vm.generateGameLobbyUrl('26'),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                  type: 'casino',
                  gameId: '26',
                  imageUrl: (_vm.$assetsEndpoint + "/pgslot.png")
                })}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('v-btn',{staticClass:"float-right",attrs:{"text":"","rounded":"","color":"pink"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby('pgslot', '')}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('base-slider',{staticClass:"base-slider--pgslot"},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return _vm._l((_vm.randomPgGames()),function(game,index){return _c('a',{key:("slot-" + index),attrs:{"href":_vm.generatePgSlotUrl(game.provider, game.gameCode),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                  type: 'pgslot',
                  gameId: game.gameCode,
                  provider: game.provider,
                  imageUrl: game.imageUrl
                })}}},[_c('img',{staticClass:"base-slider__item--image",staticStyle:{"width":"100px"},attrs:{"src":game.imageUrl,"alt":"slot"}})])})},proxy:true},{key:"loggedOut",fn:function(){return _vm._l((_vm.randomPgGames()),function(game,index){return _c('img',{key:("slot-" + index),staticClass:"base-slider__item--image",attrs:{"src":game.imageUrl,"alt":"slot"},on:{"click":function($event){return _vm.toLoginWithRedirectToPg(game.provider, game.gameCode)}}})})},proxy:true}])})],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" Dking Casino ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('v-btn',{staticClass:"float-right",attrs:{"text":"","color":"pink","rounded":"","depressed":"","href":_vm.generateGameLobbyUrl('', 'casino'),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                  type: 'casino',
                  gameId: '',
                  imageUrl: (_vm.$assetsEndpoint + "/dking.png")
                })}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('v-btn',{staticClass:"float-right",attrs:{"text":"","rounded":"","color":"pink"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby('', 'casino')}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.dkingGames),function(dkingGame){return _c('div',{key:("dking-" + (dkingGame.name)),staticClass:"game-item"},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('a',{attrs:{"href":_vm.generateGameLobbyUrl(_vm.getIdByGame(dkingGame.name), 'casino'),"target":"_blank"},on:{"click":function($event){_vm.updateRecentGame({
                      type: 'casino',
                      gameId: _vm.getIdByGame(dkingGame.name),
                      imageUrl: (_vm.$assetsEndpoint + "/" + (dkingGame.image) + ".png")
                    })}}},[_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (dkingGame.image) + ".png"),"alt":"image","width":"100%","height":"100%"}})])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (dkingGame.image) + ".png"),"alt":"image","width":"100%","height":"100%"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby(dkingGame.name, 'casino')}}})]},proxy:true}],null,true)})],1)}),0)],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" Bikini Game ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('v-btn',{staticClass:"float-right",attrs:{"color":"pink","text":"","rounded":"","depressed":"","href":_vm.generateGameLobbyUrl('', 'bikini'),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                  type: 'bikini',
                  gameId: '',
                  imageUrl: (_vm.$assetsEndpoint + "/bikini.png")
                })}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('v-btn',{staticClass:"float-right",attrs:{"rounded":"","text":"","color":"pink"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby('', 'bikini')}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.bikiniGames),function(bikiniGame){return _c('div',{key:("bikini-" + (bikiniGame.name)),staticClass:"game-item"},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('a',{attrs:{"href":_vm.generateGameLobbyUrl(_vm.getIdByGame(bikiniGame.name), 'bikini'),"target":"_blank"},on:{"click":function($event){_vm.updateRecentGame({
                      type: 'bikini',
                      gameId: _vm.getIdByGame(bikiniGame.name),
                      imageUrl: (_vm.$assetsEndpoint + "/" + (bikiniGame.image) + ".png")
                    })}}},[_c('img',{staticClass:"base-slider__item--image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (bikiniGame.image) + ".png"),"alt":"image"}})])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('img',{staticClass:"base-slider__item--image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (bikiniGame.image) + ".png"),"alt":"image"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby(bikiniGame.name, 'bikini')}}})]},proxy:true}],null,true)})],1)}),0)],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" BetWorld ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('v-btn',{staticClass:"float-right",attrs:{"text":"","color":"pink","rounded":"","depressed":"","href":_vm.generateBetworldUrl(),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                  type: 'betworld',
                  gameId: '',
                  imageUrl: (_vm.$assetsEndpoint + "/betworld.png")
                })}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('v-btn',{staticClass:"float-right",attrs:{"text":"","rounded":"","color":"pink"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby('', 'betworld')}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.betworldGames),function(betworld){return _c('div',{key:("betworld-" + (betworld.name)),staticClass:"game-item"},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('a',{attrs:{"href":_vm.generateBetworldUrl(),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                      type: 'betworld',
                      gameId: '',
                      imageUrl: (_vm.$assetsEndpoint + "/betworld.png")
                    })}}},[_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (betworld.image) + ".png"),"alt":"image","width":"100%","height":"100%"}})])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (betworld.image) + ".png"),"alt":"image","width":"100%","height":"100%"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby('', 'betworld')}}})]},proxy:true}],null,true)})],1)}),0)],1)],1),_c('v-divider'),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" ยิงปลา ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.studios),function(studio){return _c('div',{key:("studio-" + studio),staticClass:"game-item"},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('a',{attrs:{"href":_vm.generateFishGameUrl(),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                      type: 'fishing',
                      gameId: '50',
                      imageUrl: (_vm.$assetsEndpoint + "/" + studio + ".png")
                    })}}},[_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + studio + ".png"),"alt":"image","height":"100%"}})])]},proxy:true},{key:"loggedOut",fn:function(){return [_c('img',{staticClass:"game-item__image float-left",attrs:{"src":(_vm.$assetsEndpoint + "/" + studio + ".png"),"alt":"image","height":"100%"},on:{"click":function($event){return _vm.toLoginWithRedirectToLobby('fishing', 'casino')}}})]},proxy:true}],null,true)})],1)}),0)],1)],1)],1)],1),_c('section',{staticClass:"section-promotion"},[_c('v-container',[_c('center',[_c('h2',{staticClass:"my-5"},[_vm._v("โปรโมชั่น")])]),_c('v-row',_vm._l((_vm.promotions),function(promotion){return _c('v-col',{key:promotion._id,attrs:{"cols":"6","md":"4"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(){return [_c('img',{attrs:{"src":promotion.banner,"width":"100%"},on:{"click":function($event){return _vm.$router.push({ name: 'JoinPromotion', query: { to: promotion._id } })}}})]},proxy:true},{key:"loggedOut",fn:function(){return [_c('img',{attrs:{"src":promotion.banner,"width":"100%"},on:{"click":function($event){return _vm.toLoginWithRedirect({ page: 'JoinPromotion', to: promotion._id })}}})]},proxy:true}],null,true)})],1)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }