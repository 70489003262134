
































































































































































































































































































































































































































































































































import Vue from 'vue';
import TrustfactorItem from '@/components/TrustfactorItem.vue';
import BaseSlider from '@/components/BaseSlider.vue';
import Auth from '@/connector/Auth.vue';
// eslint-disable-next-line import/no-cycle
import LaunchGameMixin from '@/mixins/launchGameMixin';
// eslint-disable-next-line import/no-cycle
import PromotionService from '@/services/PromotionService';

const promotionService = new PromotionService();

export default Vue.extend({
  name: 'IndexPage',
  components: {
    TrustfactorItem,
    BaseSlider,
    Auth,
  },
  mixins: [LaunchGameMixin],
  computed: {
    trustFactors() {
      return [
        {
          imagePath: 'exchange.png',
          title: 'ฝาก - ถอน ออโต้',
          subtitle: 'รวดเร็ว ทันใจ สนุกได้ทุกเวลา',
          to: 'Deposit',
        },
        {
          imagePath: 'crown.png',
          title: 'บริการลูกค้า',
          subtitle: 'บริการด้วยใจ พร้อมดูแล 24 ชั่วโมง',
          to: 'Contact',
        },
        {
          imagePath: 'refresh.png',
          title: 'ความมั่นคง',
          subtitle: 'เท่าไหร่ก็จ่าย ไม่มีจำกัดครั้งต่อวัน',
          to: 'Withdraw',
        },
      ];
    },
    categories() {
      return [
        { name: 'dking', game: '', table_type: 'casino' },
        { name: 'bikini', game: '', table_type: 'bikini' },
        { name: 'pgslot', game: 'pgslot', table_type: 'slot' },
        { name: 'yikee', game: 'yeekee', table_type: 'casino' },
        { name: 'betworld', game: '', table_type: 'betworld' },
      ];
    },
    studios() {
      return ['fishing'];
    },
    bikiniGames() {
      return [
        { name: 'baccarat', image: 'bikini-baccarat' },
        { name: 'dragon tiger', image: 'bikini-dragon-tiger' },
        { name: 'hilo', image: 'bikini-hilo' },
        { name: 'sicbo', image: 'bikini-sicbo' },
        { name: 'roulette', image: 'bikini-roulette' },
      ];
    },
    dkingGames() {
      return [
        { name: 'baccarat', image: 'baccarat' },
        { name: 'dragon tiger', image: 'dragon-tiger' },
        { name: 'hilo', image: 'hilo' },
        { name: 'sicbo', image: 'sicbo' },
        { name: 'roulette', image: 'roulette' },
      ];
    },
    betworldGames() {
      return [
        { name: 'baccarat', image: 'bet-world-baccarat' },
        { name: 'dragon tiger', image: 'bet-world-dragon-tiger' },
        { name: 'sicbo', image: 'bet-world-sicbo' },
        { name: 'roulette', image: 'bet-world-roulette' },
      ];
    },
  },
  data() {
    const promotions: any[] = [];
    return {
      promotions,
    };
  },
  methods: {
    toLoginWithRedirectToLobby(game, tableType) {
      this.$router.push({ name: 'Login', query: { game, tableType } });
    },
    toLoginWithRedirectToPg(provider, gameId) {
      this.$router.push({ name: 'Login', query: { provider, gameId } });
    },
    toLoginWithRedirect(data: { page?, to? }) {
      this.$router.push({ name: 'Login', query: { redirect: data.page, to: data.to } });
    },
  },
  async created() {
    const { data: promotions } = await promotionService.getAvailablePromotions();
    if (promotions) {
      this.promotions = [...promotions];
    }
  },
});
